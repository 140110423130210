@import "./_base";
@import "./_variable";

@include keyframes(move) {
  0% {
    top: 110vh; }
  100% {
    top: -20vh; } }

article {
  section.banner {
    width: 100%;
    height: 100vh;
    background-color: $yellow-001;
    overflow: hidden;
    position: relative;
    figure.product {
      width: 200px;
      position: absolute;
      display: none; }
    figure.product1 {
      // width: 120px
      left: 2.8%;
      @include animation(move 14s -6s linear infinite); }
    figure.product2 {
      // width: 212px
      left: 7.7%;
      @include animation(move 15s -4s linear infinite); }
    figure.product3 {
      // width: 192px
      left: 14.8%;
      @include animation(move 15s 0s linear infinite); }
    figure.product4 {
      // width: 203px
      left: 20.6%;
      @include animation(move 16s -7s linear infinite); }
    figure.product5 {
      // width: 152px
      left: 33.2%;
      @include animation(move 15s -2s linear infinite); }
    figure.product6 {
      // width: 193px
      left: 57.9%;
      @include animation(move 16s -5s linear infinite); }
    figure.product7 {
      // width: 83px
      left: 62.9%;
      @include animation(move 15s 0s linear infinite); }
    figure.product8 {
      // width: 169px
      left: 72.3%;
      @include animation(move 14s -4s linear infinite); }
    figure.product9 {
      // width: 306px
      left: 75.3%;
      @include animation(move 15s -1s linear infinite); }
    figure.product10 {
      // width: 227px
      left: 84.2%;
      @include animation(move 15s -7s linear infinite); }
    .logo-animation {
      width: 100%;
      height: 100%;
      @include pstc5;
      .logo {
        width: 100%;
        @include pstc5; } }
    .banner-logo {
      width: 50%;
      max-width: 300px;
      display: none;
      @include pstc5; }
    .scroll {
      display: none;
      position: absolute;
      left: 50%;
      bottom: 2%;
      transform: translateX(-50%);
      .scroll-icon {
        width: 50px;
        margin: auto; }
      p {
        font-size: 1.25rem;
        color: $white-001; } }
    @include rwd(768px) {
      figure.product {
        width: 100px; }
      figure.product1 {
 }        // left: 2.8%
      figure.product2 {
        left: 7.7%; }
      figure.product3 {
        left: 14.8%; }
      figure.product4 {
        left: 20.6%; }
      figure.product5 {
        left: 33.2%; }
      figure.product6 {
        left: 57.9%; }
      figure.product7 {
        left: 62.9%; }
      figure.product8 {
        left: 72.3%; }
      figure.product9 {
        left: 75.3%; }
      figure.product10 {
        left: 84.2%; }
      .logo-animation {}
      .banner-logo {}
      .scroll {
        // bottom: 2%
        .scroll-icon {
 }          // width: 25px
        p {
 } } } }          // font-size: 1.25rem
  section.works {
    padding-top: 2.5rem;
    background-color: $black-001;
    color: $white-001;
    @include clearfix;
    .title-box {
      width: 100%;
      padding: 0 3.333333rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 10;
      .title {
        min-width: 205px;
        font-size: 6rem; }
      .menu {
        margin-left: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: auto;
        .item {
          margin: 0 1rem;
          position: relative;
          cursor: pointer;
          p {
            font-size: 2rem;
            font-weight: 300;
            white-space: nowrap; }
          & + .item {
            @include before {
              width: 1px;
              height: 100%;
              position: absolute;
              left: -1rem;
              background-color: $white-001; } }
          &.active {
            color: $orange-001;
            p {
              font-family: acumin,'Noto Sans TC', sans-serif; }
            @include after {
              width: 100%;
              height: 1px;
              position: absolute;
              left: 0;
              bottom: 0;
              background-color: $orange-001; } } } }
      .mobile-menu {
        display: none; } }
    .main {
      padding: 2rem 0;
      position: relative;
      overflow: hidden;
      .arrow {
        width: 100px;
        height: 45px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        cursor: pointer;
        &.arrow-l {
          left: 0; }
        &.arrow-r {
          right: 0; } }
      .carousel-box {
        order: 1;
        .box {
          font-size: 0;
          .item {
            display: inline-block;
            height: 50vh;
            background-position: center center;
            background-size: cover;
            cursor: pointer;
            position: relative;
            .mask {
              width: 100%;
              height: 100%;
              position: absolute;
              background-size: cover;
              transition: 1s;
              transition-timing-function: cubic-bezier(0.9,0.2,0.3,1); }
            .vip {
              width: 0;
              height: 0;
              border-top: 30px solid rgba($black-001,.8);
              border-right: 30px solid rgba($black-001,.8);
              border-left: 30px solid transparent;
              border-bottom: 30px solid transparent;
              position: absolute;
              top: 0;
              right: 0;
              .text {
                font-size: 1.333333rem;
                color: $white-001;
                position: absolute;
                top: -15px;
                left: 15px;
                transform: translate(-50%,-50%); } }
            .info-box {
              position: absolute;
              left: 2rem;
              bottom: 2rem;
              .works-title,.sub-title {
                font-size: 4rem;
                color: $white-001;
                line-height: 1; }
              .sub-title {
                padding-top: 14px; } }
            &:nth-child(1) {
              width: calc(70% - 1rem);
              margin-bottom: 2rem;
              margin-right: 1rem;
              // background-image: url(https://picsum.photos/930/494?random=1)
              .mask {
                clip: rect(0px, 70vw, 50vh, 0px);
 }                // background-image: url(https://picsum.photos/930/494?random=5)
              &:hover {
                .mask {
                  clip: rect(0px, 70vw, 0px, 0px); } } }
            &:nth-child(2) {
              width: calc(30% - 1rem);
              margin-bottom: 2rem;
              margin-left: 1rem;
              // background-image: url(https://picsum.photos/484/494?random=2)
              .mask {
                clip: rect(0px, 30vw, 50vh, 0px);
 }                // background-image: url(https://picsum.photos/484/494?random=6)
              &:hover {
                .mask {
                  clip: rect(0px, 30vw, 0px, 0px); } } }
            &:nth-child(3) {
              width: calc(40% - 1rem);
              margin-right: 1rem;
              // background-image: url(https://picsum.photos/620/494?random=3)
              .mask {
                clip: rect(0px, 40vw, 50vh, 0px);
 }                // background-image: url(https://picsum.photos/620/494?random=7)
              &:hover {
                .mask {
                  clip: rect(0px, 40vw, 0px, 0px); } } }
            &:nth-child(4) {
              width: calc(60% - 1rem);
              margin-left: 1rem;
              // background-image: url(https://picsum.photos/794/494?random=8)
              .mask {
                clip: rect(0px, 60vw, 50vh, 0px);
 }                // background-image: url(https://picsum.photos/794/494?random=5)
              &:hover {
                .mask {
                  clip: rect(0px, 60vw, 0px, 0px); } } } } } } }
    .btn {
      margin-bottom: 2rem;
      order: 2;
      padding: 0 2rem;
      font-size: 1.5rem;
      color: $black-002;
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
      float: right; }
    @include rwd(768px) {
      // padding-top: 2.5rem
      .title-box {
        padding: 0 2rem;
        .title {
          min-width: 110px;
          font-size: 3.2rem; }
        .menu {
          display: none; }
        .mobile-menu {
          display: block;
          width: 150px;
          border-radius: 16px;
          background-color: $orange-002;
          color: $black-001;
          cursor: pointer;
          position: relative;
          .selected {
            width: 100%;
            height: 32px;
            padding-bottom: 2px;
            padding-right: 10%;
            box-sizing: border-box;
            font-size: 1.2rem;
            font-weight: 300;
            background-image: url(../images/select-icon.png);
            background-repeat: no-repeat;
            background-size: 13px;
            background-position: 90% center;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 4; }
          .option-box {
            display: none;
            width: 100%;
            padding-top: 32px;
            background-color: $orange-002;
            border-radius: 16px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            .opt {
              padding: 0 1rem;
              font-size: 1.2rem;
              line-height: 32px;
              font-weight: 600;
              &:first-child {
                padding-top: 8px;
                border-top: 2px solid $black-001; }
              &.active {
                color: $white-001;
                text-decoration: underline; } } } } }
      .main {
        // padding: 2rem 0
        .arrow {
          &.arrow-l {
            left: -50px; }
          &.arrow-r {
            right: -50px; } }
        .carousel-box {
          .box {
            font-size: 0;
            .item {
              display: block;
              height: 24vh;
              // height: 400px
              // margin-bottom: 2rem
              .mask {
                width: 100%;
                height: 100%; }
              .vip {
                border-top: 20px solid rgba($black-001,.8);
                border-right: 20px solid rgba($black-001,.8);
                border-left: 20px solid transparent;
                border-bottom: 20px solid transparent;
                .text {
                  font-size: 1rem;
                  top: -10px;
                  left: 10px; } }
              .info-box {
                .works-title,.sub-title {
                  font-size: 2rem; }
                .sub-title {
                  padding-top: 7px; } }
              &:nth-child(1),&:nth-child(2),&:nth-child(3),&:nth-child(4) {
                width: 100%;
                margin: 0;
                margin-bottom: 1vh;
                .mask {
                  clip: rect(0px, 100vw, 24vh, 0px); }
                &:hover {
                  .mask {
                    clip: rect(0px, 100vw, 0px, 0px); } } } } } }
        .btn {
          padding: 0 2rem;
          font-size: 1.333333rem; } } } }
  section.about {
    width: 100%;
    .wrapper {
      height: calc(110vh + 160px);
      padding: 0 5rem;
      position: relative;
      .bg,.bg-m {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0; }
      .bg {
        display: block;
        @include rwd(768px) {
          display: none; } }
      .bg-m {
        display: none;
        @include rwd(768px) {
          background-attachment: scroll;
          display: block; } }
      .box {
        max-width: 1200px;
        height: 100%;
        margin: auto;
        position: relative;
        .content {
          width: 100%;
          height: 0;
          padding-bottom: 60%;
          background-image: url(../images/about-border.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          @include pstc0;
          .about-title {
            font-size: 6rem;
            color: $white-001;
            position: absolute;
            right: 5rem;
            bottom: 2.5rem; }
          p {
            font-size: 5rem;
            position: absolute;
            left: 3.5rem;
            top: 3.5rem; } } } }
    .member {
      background-color: $white-001;
      position: relative;
      overflow: hidden;
      canvas#arrowCanvas {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        pointer-events: none; }
      .arrow {
        display: none;
        width: 100px;
        height: 45px;
        position: absolute;
        top: 2rem;
        z-index: 2;
        cursor: pointer;
        &.arrow-l {
          left: 0;
          transform: translateX(-50%); }
        &.arrow-r {
          right: 0;
          transform: translateX(50%); } }
      .carousel-box {
        display: flex;
        // cursor: none
        .box {
          // width: 360px
          position: relative;
          overflow: hidden;
          .content {
            width: 100%;
            padding-bottom: 160%;
            .title-info {
              position: absolute;
              top: 0;
              left: 0;
              .member-title {
                padding: 1.818vw;
                p {}
                font-size: 5.46vw;
                line-height: .8; }
              figure.arrow-icon {
                width: 12vw;
                padding-left: 1.818vw; } }
            figure.mascot {
              width: 10vw;
              position: absolute;
              left: 0;
              bottom: 0; }
            figure.member-pic {
              width: 100%;
              position: absolute;
              z-index: 1;
              transition: 1s; }
            .mask {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2; }
            .name {
              font-size: 3.6vw;
              font-weight: 500;
              line-height: 1;
              position: absolute;
              top: 0.9vw;
              left: 0.9vw;
              z-index: 2; }
            .info {
              position: absolute;
              bottom: 1.5vw;
              left: 0.9vw;
              z-index: 2;
              .position {
                font-size: 2vw;
                font-weight: 300; }
              .motto {
                font-size: 1.2vw;
                font-weight: 300; } } }
          &:hover {
            .content {
              figure.member-pic {
                transform-origin: 50% 50%;
                transform: scale(1.1); } } } } } }
    @include rwd(1320px) {
      .wrapper {
        .box {
          .content {
            .about-title {
              font-size: 5.455vw;
              right: 4.5vw;
              bottom: 1.5vw; }
            p {
              font-size: 4.55vw;
              left: 3vw;
              top: 3vw; } } } }
      .member {
        .arrow {
          // width: 100px
          // height: 45px
          // top: 2rem
          &.arrow-l {
 }            // transform: translateX(-50%)
          &.arrow-r {
 } }            // transform: translateX(50%)
        .carousel-box {
          .box {
            .content {
              // padding-bottom: 160%
              .title-info {
                .member-title {
                  padding: 1.818vw;
                  p {}
                  font-size: 5.46vw; }
                figure.arrow-icon {
                  width: 12vw;
                  padding-left: 1.818vw; } }
              figure.mascot {
                width: 10vw; }
              figure.member-pic {}
              .mask {}
              .name {
                font-size: 3.6vw;
                top: 0.9vw;
                left: 0.9vw; }
              .info {
                bottom: 1.5vw;
                left: 0.9vw;
                .position {
                  font-size: 2vw; }
                .motto {
                  font-size: 1.2vw; } } } } } } }
    @include rwd(768px) {
      .member {
        .arrow {
          display: block;
          width: 77px;
          height: 35px;
          top: 50%;
          &.arrow-l {
            transform: translate(-50%,-50%); }
          &.arrow-r {
            transform: translate(50%,-50%); } }
        .carousel-box {
          .box {
            &.title-box {}
            .content {
              // padding-bottom: 160%
              .title-info {
                .member-title {
                  padding: 6vw 2vw 0;
                  p {}
                  font-size: 7.45vw; }
                figure.arrow-icon {
                  // width: 12vw
                  padding-left: 2vw; } }
              figure.mascot {
                width: 20vw; }
              figure.member-pic {}
              .mask {}
              .name {
                font-size: 8.38vw;
                top: 2vw;
                left: 1vw; }
              .info {
                bottom: 3.65vw;
                left: 1vw;
                .position {
                  font-size: 4vw; }
                .motto {
                  font-size: 3vw; } } } } } } }
    @include rwd(540px) {
      .wrapper {
        height: 100vh;
        padding: 0 2rem;
        .box {
          .content {
            width: 100%;
            padding-bottom: 133.7%;
            background-image: url(../images/about-border-m.png);
            .about-title {
              font-size: 7.46vw;
              right: 5vw;
              bottom: 1.3rem; }
            p {
              font-size: 5.6vw;
              left: 3vw;
              top: 15vw; } } } } } }
  section.instagram {
    width: 100%;
    padding: 3.333333rem;
    padding-bottom: 0;
    background-color: $black-001;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    .instagram-title {
      font-size: 6rem;
      color: $white-001;
      p {
        display: inline-block; }
      span {
        font-weight: 300; } }
    .arrow {
      display: none; }
    .wrapper {}
    .box {
      width: 100%;
      max-width: 1200px;
      padding-top: 5rem;
      // padding-bottom: 2rem
      margin: auto;
      display: flex;
      justify-content: space-around;
      .item {
        position: relative;
        figure.phone {
          margin-bottom: -50%; }
        .btn {
          width: 100%;
          height: 80px;
          // padding-top: 8px
          // margin-top: -18rem
          background-color: $orange-002;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 40rem;
          box-sizing: border-box;
          position: absolute;
          bottom: 33%;
          z-index: 1;
          .icon {}
          p {
            padding-left: 0.5rem;
            padding-bottom: 6px;
            color: $black-001;
            font-size: 2.5rem; } } } }

    @include rwd(768px) {
      padding: 2rem;
      padding-bottom: 0;
      .instagram-title {
        font-size: 2.666667rem;
        text-align: center; }
      .arrow {
        display: block;
        width: 77px;
        height: 35px;
        position: absolute;
        top: 50%;
        z-index: 2;
        cursor: pointer;
        &.arrow-l {
          left: 0;
          transform: translate(-50%,-50%); }
        &.arrow-r {
          right: 0;
          transform: translate(50%,-50%); } }
      .box {
        width: 74.4%;
        padding-top: 5vw;
        // padding-bottom: 2vw
        .item {
          figure.phone {
            margin-bottom: -30%; }
          .btn {
            height: 60px;
            padding-top: 6px;
            border-radius: 30px;
            bottom: 20%;
            .icon {}
            p {
              // padding-left: 0.5rem
              font-size: 5.6vw; } } } } } } }





